
import './Loader.css'
import loadergif from './luiss_logo_animation.gif'
function Loader() {
    const init = async () => {
        
    };

    return (
        <>
            <div className="loader-wrapper">
                <div className='loading'>
                </div></div>
                

        </>
    );
}

export default Loader;