
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const DEFAULT_LANGUAGE = 'it';
const LANGUAGE_KEY = 'lang';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            code:"code",
            name:"name",
            credits:"credits",
            "further credits":"Further credits",
            Credits:"Credits",
            search:"Search",
            professorSearch:"Search for professor:",
            courseSearch:"Search for course:",
            "CORSI UNDERGRADUATE": "UNDERGRADUATE COURSES",
            "Per saperne di più su": "Read More",
            "Presidente" : "President",
            "Membri Della Commissione":"Members of the Commission",
            "CORSI GRADUATE": "GRADUATE COURSES"
        }
    },
    it:{
        translation:{
            code:"codice",
            name:"nome",
            credits:"crediti",
            "further credits":"Ulteriori crediti",
            Credits:"Crediti",
            search:"Cerca",
            professorSearch:"Cerca per professore:",
            courseSearch:"Cerca per corso:",
            "UNDERGRADUATE COURSES": "CORSI UNDERGRADUATE",
            "Read More":"Per saperne di più su",
           "President": "Presidente" ,
           "Members of the Commission": "Membri Della Commissione",
           "GRADUATE COURSES": "CORSI GRADUATE",
        }
    }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "it",  // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

const getSiteLanguage = () => {
    const lang = localStorage.getItem(LANGUAGE_KEY) || DEFAULT_LANGUAGE;

    return lang;
}

const setSiteLanguage = (lang) => {
    localStorage.setItem(LANGUAGE_KEY, lang || DEFAULT_LANGUAGE);
    i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: lang, 
    interpolation: {
      escapeValue: false
    }
  });

}

const handleLanguage = (location, navigate) => {
    // The function is called in app.tsx and in that scope, it seems that 
    // we don't have access to useparams, so we are using location,
    // to find the language
    const locationParts = location.pathname.split('/');
    const lang = locationParts.length > 1 ? locationParts[1] : null;

    if (!lang) {
        navigate('/it');
    }

    setSiteLanguage(lang);
}

const buildUrl = (link) => {
    const lang = getSiteLanguage();

    if (link.url) {
        return link.url;
    }

    return "/" + lang + "/" + link.postType + "/" + link.slug
}

export {
    getSiteLanguage,
    setSiteLanguage,
    buildUrl,
    handleLanguage
};

export default i18n;